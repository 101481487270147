import { Backdrop, Box, Button, ButtonBase, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import MaterialCard from 'common/components/cards/materialCard';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import {
  ActionNameElement,
  ColumnsBelowSearch,
  DateElement,
  NumberGenericElement,
} from 'common/components/table/tableComponents';
//import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import React, { lazy, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { bindActionCreators } from 'redux';
import ConfirmationCard from 'common/components/cards/confirmationCard/confirmationCard';
import { BASE_S3 } from 'config/consts';
import {
  deleteActionDraft,
  getLazyStationActionsDrafts,
  getTotalNumberActionsDrafts,
  onClickDraftAction,
  onClickDraftShare,
} from '../actionsDrafts/actionsDraftsScripts';
import { getTemplates } from '../actionsFile';
import { getLazyStationHistory, getTotalArchivedActions } from '../actionsHistory/actionsHistoryActions';
import {
  getLazyPartnerWaitingApprovalActions,
  getTotalNumberWaitingApprovalActions,
  getLazyStationActiveActions,
  getTotalNumberActiveActions,
  onClickActionResults,
  duplicateAction,
  onClickAction,
} from '../activeActions/activeActionsScripts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.css'; 

import { FileCopy, BarChart, Edit, Delete, Send } from '@material-ui/icons';
 

function ActiveActions(props) {
  const { auth } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState({});
  const [item, setItem] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [itemHistory] = React.useState(null);
  const [finishDelete, setFinishDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDuplicateBackDrop, setOpenDuplicateBackDrop] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  
  const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
        }
  });

  const options = [
      { label: 'Online', value: 'online' },
      { label: 'Esperando aprovação', value: 'awaitingApproval' },
      { label: 'Rascunho', value: 'drafts' },
      { label: 'Encerradas', value: 'closed'},
  ];

  useEffect(() => {
    props.getTemplates(auth.user.establishments[0].id);
    setSelectedOption('online');
  }, []);

  useEffect(() => {
    if (selectedOption === 'awaitingApproval') {
      setTotalRecords(props.actionsTotal.total);
      setData(props.activeActions.list);
    }
    if (selectedOption === 'drafts') {
      setTotalRecords(props.draftActionsTotal.total);
      setData(props.actionsDrafts.list);
    }
    if (selectedOption === 'online') {
      setTotalRecords(props.actionsTotal.total);
      setData(props.activeActions.list);
    }
    if (selectedOption === 'closed') {
      setTotalRecords(props.actionHistoryTotal.total);
      setData(props.actionsHistory.list);
    }
    setLoading(false);
  }, [
    props.activeActions.list,
    props.actionsDrafts.list,
    props.actionsHistory.list,
  ]);


  useEffect(() => {
    if (!selectedOption){
      return;
    }
    lazyState.first = 0;
    setLoading(true);
    if (selectedOption === 'drafts') {
      props.getTotalNumberActionsDrafts(auth.user.establishments[0].id, lazyState);
      props.getLazyStationActionsDrafts(auth.user.establishments[0].id, lazyState);
    }
    if (selectedOption === 'online') {
      props.getTotalNumberActiveActions(auth.user.establishments[0].id, lazyState);
      props.getLazyStationActiveActions(auth.user.establishments[0].id, lazyState);
    }
    if (selectedOption === 'awaitingApproval') {
      props.getTotalNumberWaitingApprovalActions(auth.user.establishments[0].id, lazyState);
      props.getLazyPartnerWaitingApprovalActions(auth.user.establishments[0].id, lazyState);
    }
    if (selectedOption === 'closed') {
      props.getTotalArchivedActions(auth.user.establishments[0].id, lazyState);
      props.getLazyStationHistory(auth.user.establishments[0].id, lazyState);
    }
  }, [selectedOption]);

  
  const renderTemplatesList = () => {
    const templatesBase = props.actions.templatesList.filter(
      item => item.category === 1
    );

    if (templatesBase.length > 0) {
      return templatesBase.map((item, index) => {
        return (
          <MaterialGrid
            item
            xs={12}
            md={3}
            key={index}
            style={{ marginBottom: 10 }}
          >
            <MaterialCard
              horizontalCard
              onClick={() => {
                setOpen(true);
                setTemplate(item);
              }}
              name={item.name}
              image={`${BASE_S3}/${item.owner_image || item.image}`}
              buttonLabel="Ativar"
              type={item.type.name}
            />
          </MaterialGrid>
        );
      });
    } else {
      return null;
    }
  };
  const renderEmptyCard = () => {
    return (
      <MaterialGrid item xs={12} md={3} style={{ marginBottom: 10 }}>
        <MaterialCard
          horizontalCard
          router={props.router}
          cardEmpty
          onClick={() => {
            props.router.push('/owner/acoes');
          }}
          name={'Galeria de Atividades'}
          image={``}
          type={''}
        />
      </MaterialGrid>
    );
  };
  const renderCardsRow = () => {
    return (
      <MaterialGrid
        container
        spacing={3}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          overflowY: 'hidden',
          marginBottom: '.7rem',
          marginTop: 15,
        }}
      >
        {isMobile ? null : renderEmptyCard()}
        {renderTemplatesList()}
      </MaterialGrid>
    );
  };


const dateBodyTemplate = (rowData) => {
  return (
    <span>{rowData.startDate}</span>
  );
};

const nameBodyTemplate = (rowData) => {
  return (
    <ActionNameElement
      className="p-column-filter"
      singleColum={false}
      actionName={rowData['name']}
      image={rowData['image']}
    />
  );
  };

  const participantsBodyTemplate = (rowData) => {
    return (
      <NumberGenericElement
          category="participantes"
          divider
          valueSec={rowData['limit']}
          value={rowData['userActions']?.length || 0}
        />
    );
  };


  const botaoAcaoEditar = (rowData) => {
    return (
      <Tooltip title="Editar">
      <IconButton
          aria-label="Editar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickAction(rowData, props.router, 'online')}
        >
          <Edit />
          </IconButton>
          </Tooltip>
    );
  }

  const botaoAcaoDuplicar = (rowData) => {
    return (
      <Tooltip title="Duplicar">
      <IconButton
          aria-label="Duplicar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() =>
            props.duplicateAction(rowData, props.router, setOpenDuplicateBackDrop)
          }
        >
          <FileCopy />
        </IconButton>
        </Tooltip>
    );
  }

  const botaoAcaoVerResultados = (rowData) => {
    return (
      <Tooltip title="Ver resultados">
      <IconButton
          aria-label="Ver resultados"
          style={{ backgroundColor: 'transparent' }}
          onClick={() =>
            props.onClickActionResults(rowData, props.router, 'online')
          }
        >
          <BarChart />
        </IconButton>
        </Tooltip>
    );
  }

  const botaoAcaoDeletar = (rowData) => {
    return (
      <Tooltip title="Deletar">
      <IconButton
          aria-label="Deletar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            setOpenConfirmation(true);
            setItem(rowData.id);
          }}
        >
          <Delete />
        </IconButton>
        </Tooltip>
    );
  }

  const botaoAcaoSubmeterAprovacao = (rowData) => {
    return (
      <Tooltip title="Submeter para aprovação">
      <IconButton
          aria-label="Submeter para aprovação"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickDraftShare(rowData, props.router)}
        >
          <Send />
        </IconButton>
        </Tooltip>
    );
  }

  const botaoAcaoEditarRascunho = (rowData) => {
    return (
      <Tooltip title="Editar">
      <IconButton
          aria-label="Editar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickDraftAction(rowData, props.router)}
        >
          <Edit />
        </IconButton>
        </Tooltip>
    );
  }

  const acoesBodyTemplate = (rowData) => {
    if (selectedOption === 'online') {
      return (
        <div>
          {botaoAcaoEditar(rowData)}
          {botaoAcaoDuplicar(rowData)}
          {(botaoAcaoVerResultados(rowData))}
        </div>
      );
    }
    if (selectedOption === 'drafts') {
      return (
        <div>
          {botaoAcaoEditarRascunho(rowData)}
          {botaoAcaoSubmeterAprovacao(rowData)}
          {botaoAcaoDuplicar(rowData)}
          {botaoAcaoDeletar(rowData)}
        </div>
      );
    }
    if (selectedOption === 'awaitingApproval') {
      return (
      <div>
        {botaoAcaoEditarRascunho(rowData)}
        {botaoAcaoDuplicar(rowData)}
      </div>
      );
    }
    if (selectedOption === 'closed') {
      return (
        <div>
          {botaoAcaoEditar(rowData)}
          {botaoAcaoDuplicar(rowData)}
          {botaoAcaoVerResultados(rowData)}
        </div>
      );
    }
  }

   const onPage = (event) => {
    setlazyState(event);

   };

   const itemTemplate = (option) => {
     return (
       <div
         style={{
           paddingRight: '10px',
           paddingLeft: '10px',
         }}
       >
         {option.label}
       </div>
     );
   };

   

   useEffect(() => {
    setLoading(true);
    if (selectedOption === 'drafts') {
       props.getLazyStationActionsDrafts(auth.user.establishments[0].id, lazyState);
     }
     if (selectedOption === 'online') {
       props.getLazyStationActiveActions(auth.user.establishments[0].id,lazyState);
     }
     if (selectedOption === 'awaitingApproval') {
       props.getLazyPartnerWaitingApprovalActions(auth.user.establishments[0].id, lazyState);
     }
     if (selectedOption === 'closed') {
       props.getLazyStationHistory(auth.user.establishments[0].id, lazyState);
     }
   }, [lazyState]);

 
   const onFilter = (event) => {
     event['first'] = 0;
     setlazyState(event);     
   };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setlazyState((prevState) => ({
      ...prevState,
      first: newPage * rowsPerPage, 
      page: newPage
    }));
  };
  
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0)
    setlazyState((prevState) => ({
      ...prevState,
      first: 0,
      rows: newRowsPerPage 
    }));
  };


  const useStyles = makeStyles({
    root: {
      display: 'contents',
      '& .p-datatable-tbody':{
        display: 'none'
      },
      '& .p-column-header-content': {
        display: 'none'
      },

    },
  });

  const classes = useStyles();

  return (
    <MaterialGrid
      container
      spacing={3}
      style={{
        maxWidth: '86vw',
        paddingBottom: '4rem',
      }}
    >
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.actionsDrafts.buttonLoading}
        cancelClose={() => setOpenConfirmation(false)}
        general={props.general}
        title={`${item ? 'Deletar rascunho' : 'Deletar atividade'}`}
        confirmButtonColor={'primary'}
        text={`${
          item
            ? `Tem certeza que deseja deletar esse rascunho`
            : `Tem certeza que deseja deletar essa atividade`
        }? Essa ação não poderá ser desfeita`}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item)
            props.deleteActionDraft(item, setFinishDelete, setOpenConfirmation);
          if (itemHistory) {
            props.deleteActionSuspended(
              itemHistory,
              setFinishDelete,
              setOpenConfirmation
            );
          }
        }}
      />
      <MaterialGrid
        container
        style={{
          display: 'flex',
          marginBottom: '.7rem',
        }}
      >
        {renderCardsRow()}
      </MaterialGrid>

      <MaterialGrid
        container
        style={{
          marginTop: isMobile ? 0 : 15,
          marginBottom: isMobile ? 0 : 15,
        }}
      >
        <div>
          <SelectButton
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.value)}
            optionLabel="label"
            options={options}
            allowEmpty="false"
            style={{
              width: '100%',
              marginBottom: 10
            }}
            itemTemplate={itemTemplate}
          />
        </div>

        <div className={classes.root} >
          <DataTable
            value={data}
            lazy
            totalRecords={totalRecords}
            rows={lazyState.rows}
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            scrollable
            loading={loading}
            first={lazyState.first}
            onFilter={onFilter}
            onPage={onPage}
            resizableColumns
            filterDisplay="row"
            filters={lazyState.filters}
            style={{ width: '100%' }}
          >
            <Column
              field="name"
              header="Nome"
              sortable
              filter
              filterPlaceholder="Pesquisar"
              style={{ minWidth: '14rem', minHeight: '45rem' }}
            ></Column>
        
          </DataTable>
        </div>

      </MaterialGrid>
      <>
      <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Início da Publicação</TableCell>
            <TableCell>Fim da Publicação</TableCell>
            <TableCell>Participantes</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell>
              {nameBodyTemplate(row)} 
              </TableCell>    
              <TableCell>{dateBodyTemplate(row)}</TableCell>
              <TableCell>{dateBodyTemplate(row)}</TableCell>
              <TableCell>{participantsBodyTemplate(row)}</TableCell>
              <TableCell>{acoesBodyTemplate(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
      />
    </TableContainer>
      </>
      <ConfirmationCard
        onClose={() => setOpen(false)}
        open={open}
        item={template.id}
        station_id={null}
        partner_id={props.auth.user.establishments[0].id}
        sponsored={true}
        router={props.router}
      />
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={openDuplicateBackDrop}
      >
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>
          Gerando duplicação da atividade...
        </span>
      </Backdrop>
    </MaterialGrid>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    actionsDrafts: state.actionsDrafts,
    actionsHistory: state.actionsHistory,
    actions: state.actions,
    general: state.general,
    actionsTotal: state.activeActions.actionsTotal,
    draftActionsTotal: state.actionsDrafts.draftActionsTotal,
    actionHistoryTotal: state.actionsHistory.total,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getLazyStationActiveActions,
      getTotalNumberActiveActions,
      getLazyStationActionsDrafts,
      getTotalNumberActionsDrafts,
      getTemplates,
      getLazyStationHistory,
      getTotalArchivedActions,
      onClickDraftAction,
      deleteActionDraft,
      onClickDraftShare,
      onClickActionResults,
      getLazyPartnerWaitingApprovalActions,
      getTotalNumberWaitingApprovalActions,
      duplicateAction,
      onClickAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveActions);
